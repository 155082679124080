
import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import logo from '../../Assest/lo.jpeg';
import classes from "./Wpp.module.css"
import Fade from "react-reveal/Fade"

export default function Wpp() {
    return (
        // <div className="App">
        //     <FloatingWhatsApp
        //         phoneNumber="+918052235594"
        //         accountName="EshikaTech Pvt Ltd"
        //         avatar={logo}
        //         chatMessage="Please feel free to ask your tools and engineering related questions, and I'll provide detailed responses here."
        //         allowEsc
        //         allowClickAway
        //         notification
        //         notificationSound
        //     />
        // </div>

 <div className={classes.container}>
    <Fade left>
            <a 
                href="https://wa.me/8052235594" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                <img
                    src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                    alt="WhatsApp Icon"
                    className={classes.whatsappIcon}
                />
            </a>
            </Fade>
        </div>
    );
}