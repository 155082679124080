import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from "react-reveal/Fade"
import { useRef } from 'react';

import classes from "./Foot1.module.css"
import logo2 from './../../Assest/ee.png';
import Heart from "./../../Assest/eziigj.gif"

import { FaHouseDamage, FaAddressCard, FaList, FaAddressBook, FaInstagram, FaFacebook, FaLinkedin } from "react-icons/fa";


const Foot = () => {


    return (
        <div className={classes.ftr} >

<Fade left>
            <div className={classes.ftr1} >

                <span className={classes.sp1}>Ready To Start Your Project?</span>
                <br />
                <span className={classes.sp2}> Whether it's for design, development, support, server, remota work, or whatever, EshikaTech Pvt Ltd is in the business of delivering the best results. Because otherwise, what's the point Give us a call or drop us a line and we'd love to talk with you about your requirement </span>

            </div>

</Fade>

<Fade right>
            <div className={classes.ftr2} >


                <div className={classes.ftr22}>

                    <div className={classes.c1}>

                        <h2 className={classes.h3}> Social </h2>

                        <div className={classes.yz}>

                            <FaInstagram className={classes.icon3} />
                            <FaFacebook className={classes.icon3} />
                            <FaLinkedin className={classes.icon3} />


                        </div>


                        <h4 className={classes.h4}> Let's Rock And Roll Together</h4>

                        <div className={classes.yz}>
                            <a href='tel:+918052235594'><button className={classes.hif}>Get In Touch</button></a>
                        </div>

                    </div>


                    <div className={classes.c1}>

                        <h2 className={classes.h3}> Quick links </h2>

                        <div className={classes.hu}>
                            <li><a href="/ " className={classes.apal}>Home</a></li>
                            <li><a href="/about" className={classes.apal}>About</a></li>
                            <li><a href="/service" className={classes.apal}>Services</a></li>
                            <li><a href="/Contact" className={classes.apal}>Contact Us</a></li>

                        </div>

                    </div>

  <div className={classes.c1}>

                        <h2 className={classes.h3}> Our Services</h2>
                           <div className={classes.hu}>
                            <li><a href="/service" className={classes.apal}>Website Designing</a></li>
                            <li><a href="/service" className={classes.apal}>Website Development</a></li>
                            <li><a href="/service" className={classes.apal}>Responsive Webapp</a></li>
                            <li><a href="/service" className={classes.apal}>Domain & Hosting</a></li>
                            <li><a href="/service" className={classes.apal}>C M S</a></li>

                        

                        </div>
                    </div>



                    <div className={classes.c1}>

                        <h2 className={classes.h3}> Contact Us</h2>

   <div className={classes.hu}>
<span className={classes.apal2}>INDIA</span>
<br/>
<span className={classes.apaldd}>Kandiwali East, Mumbai, Maharashtra 400101</span>
<br/> <br/>

<p className={classes.apal32}>
<span className={classes.apal}>Email :</span><br/>
<span className={classes.apal}><a href='mailto:info@eshikatech.com' className={classes.fd}>info@eshikatech</a></span>

<br/>

<span className={classes.apal}>Phone :</span><br/>
<span className={classes.apal}><a href='tel:+918052235594' className={classes.fd}>+91 8052235594</a></span>
</p>
</div>
                    </div>


                </div>

            </div>

</Fade>

<Fade left>

<div className={classes.ftr3}>



        <div className={classes.lineContainer}>
            <span className={classes.line}></span>
            <span className={classes.text}>WWW.ESHIKATECH.COM</span>
            <span className={classes.line}></span>
        </div>
</div>

</Fade>
        </div>
    )
}


export default Foot;