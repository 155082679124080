import React, { useEffect } from 'react';
import B from "./../Component/Portfolio/Banner";
import P from "./../Component/Portfolio/Portfolio";

const Portfolio = () => {

    const isMobile = window.innerWidth < 768; // Adjust the breakpoint as needed


  useEffect(() => {
    window.scrollTo(0, 0);

    // portfolio Page Meta Tags
    document.title = 'Eshikatech: Innovative Tech Solutions | Portfolio';

    // Set meta description dynamically
    const portfolioMetaDescription = document.createElement('meta');
    portfolioMetaDescription.name = 'description';
    portfolioMetaDescription.content = 'Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.';
    document.head.appendChild(portfolioMetaDescription);

    // Set meta keywords dynamically
    const portfolioMetaKeywords = document.createElement('meta');
    portfolioMetaKeywords.name = 'keywords';
    portfolioMetaKeywords.content = 'Eshikatech, Tech Solutions, Innovative Technology, Business Tech Services, IT Solutions, Technology Services';
    document.head.appendChild(portfolioMetaKeywords);

    // Set canonical link dynamically
    const portfolioCanonicalLink = document.createElement('link');
    portfolioCanonicalLink.rel = 'canonical';
    portfolioCanonicalLink.href = 'https://eshikatech.com/portfolio';
    document.head.appendChild(portfolioCanonicalLink);

    // Set Open Graph title dynamically
    const portfolioOgTitle = document.createElement('meta');
    portfolioOgTitle.property = 'og:title';
    portfolioOgTitle.content = 'Eshikatech: Innovative Tech Solutions | Portfolio';
    document.head.appendChild(portfolioOgTitle);

    // Set Open Graph description dynamically
    const portfolioOgDescription = document.createElement('meta');
    portfolioOgDescription.property = 'og:description';
    portfolioOgDescription.content = 'Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.';
    document.head.appendChild(portfolioOgDescription);

    // Set Open Graph URL dynamically
    const portfolioOgUrl = document.createElement('meta');
    portfolioOgUrl.property = 'og:url';
    portfolioOgUrl.content = 'https://eshikatech.com/portfolio';
    document.head.appendChild(portfolioOgUrl);

    // Set Open Graph site name dynamically
    const portfolioOgSiteName = document.createElement('meta');
    portfolioOgSiteName.property = 'og:site_name';
    portfolioOgSiteName.content = 'Eshikatech Pvt Ltd';
    document.head.appendChild(portfolioOgSiteName);

    // Set Open Graph image dynamically
    const portfolioOgImage = document.createElement('meta');
    portfolioOgImage.property = 'og:image';
    portfolioOgImage.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(portfolioOgImage);

    // Set Open Graph secure image URL dynamically
    const portfolioOgImageSecure = document.createElement('meta');
    portfolioOgImageSecure.property = 'og:image:secure_url';
    portfolioOgImageSecure.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(portfolioOgImageSecure);

    // Set Open Graph image width dynamically
    const portfolioOgImageWidth = document.createElement('meta');
    portfolioOgImageWidth.property = 'og:image:width';
    portfolioOgImageWidth.content = '300';
    document.head.appendChild(portfolioOgImageWidth);

    // Set Open Graph image height dynamically
    const portfolioOgImageHeight = document.createElement('meta');
    portfolioOgImageHeight.property = 'og:image:height';
    portfolioOgImageHeight.content = '200';
    document.head.appendChild(portfolioOgImageHeight);


    // Add Twitter Card Tags
    const twitterCard = document.createElement('meta');
    twitterCard.name = 'twitter:card';
    twitterCard.content = 'summary_large_image';
    document.head.appendChild(twitterCard);

    const twitterTitle = document.createElement('meta');
    twitterTitle.name = 'twitter:title';
    twitterTitle.content = 'Eshikatech: Innovative Tech Solutions | Portfolio';
    document.head.appendChild(twitterTitle);

    const twitterDescription = document.createElement('meta');
    twitterDescription.name = 'twitter:description';
    twitterDescription.content = 'Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.';
    document.head.appendChild(twitterDescription);

    const twitterImage = document.createElement('meta');
    twitterImage.name = 'twitter:image';
    twitterImage.content = 'https://eshikatech.com/lo.jpeg';
    document.head.appendChild(twitterImage);





    // Add Structured Data for portfolio Page
    const portfolioStructuredData = document.createElement('script');
portfolioStructuredData.type = 'application/ld+json';
portfolioStructuredData.textContent = JSON.stringify({
  "@context": "https://schema.org",
  "@type": "WebSite",
  "name": "Eshikatech Pvt Ltd",
  "url": "https://eshikatech.com/portfolio",
  "headline": "Eshikatech: Innovative Tech Solutions", // Add this line
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://eshikatech.com/search?query={search_term_string}",
    "query-input": "required name=search_term_string"
  },
  "description": "Eshikatech offers cutting-edge technology solutions and services to help your business thrive. Discover our innovative approach to tech.",
  "inLanguage": "en",
    "sitelinks": [
    {
      "@type": "WebPage",
      "name": "portfolio",
      "url": "https://eshikatech.com"
    },
    {
      "@type": "WebPage",
      "name": "Services",
      "url": "https://eshikatech.com/service"
    },
    {
      "@type": "WebPage",
      "name": "About Us",
      "url": "https://eshikatech.com/about"
    },
        {
      "@type": "WebPage",
      "name": "Portfolio",
      "url": "https://eshikatech.com/portfolio"
    },
    {
      "@type": "WebPage",
      "name": "Contact",
      "url": "https://eshikatech.com/Contact"
    }
  ]
});




    document.head.appendChild(portfolioStructuredData);

    // Cleanup on component unmount
    return () => {
      document.title = 'Eshikatech: Innovative Tech Solutions | Portfolio';
      document.head.removeChild(portfolioMetaDescription);
      document.head.removeChild(portfolioMetaKeywords);
      document.head.removeChild(portfolioCanonicalLink);
      document.head.removeChild(portfolioOgTitle);
      document.head.removeChild(portfolioOgDescription);
      document.head.removeChild(portfolioOgUrl);
      document.head.removeChild(portfolioOgSiteName);
      document.head.removeChild(portfolioOgImage);
      document.head.removeChild(portfolioOgImageSecure);
      document.head.removeChild(portfolioOgImageWidth);
      document.head.removeChild(portfolioOgImageHeight);
      document.head.removeChild(portfolioStructuredData);
    };

  }, []);

  return (
    <div>
        <B />
      <P />
    </div>




















  );
}

export default Portfolio;
