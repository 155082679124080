import React from "react";
import Fade from "react-reveal/Fade"
import { FaArrowRight } from 'react-icons/fa';
import classes from "./Portfolio.module.css";
import k2 from "./../../logo/web.png"
import k1 from "./../../logo/web.png"
import k3 from "./../../logo/web.png"
import k4 from "./../../logo/web.png"
import k5 from "./../../logo/web.png"


 import rs1 from './../../Assest/s1.png';
 import rs2 from './../../Assest/s2.png';
import rs3 from './../../Assest/s3.png';
import rs4 from './../../Assest/s4.png';
import rs5 from './../../Assest/s5.png';

const Service = () => {
    const services = [
        {   title:"EshikaTech PVT Ltd",
            link:"https://eshikatech.com",
            logo:k4,
            image: rs5,
            alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
        },
        {
             title:"Nmt Packaging Pvt Ltd",
             link:"https://nmt.eshikatech.com/",
            logo:k5,
            image:rs4,
            alt: "Mobile UX/UI illustration with a smartphone displaying various app elements"
        },
        {
             title:"Rajeshwaree Packging Pvt Ltd",
             link:"https://rajeshwareepackaging.com/",
            logo:k1,
            image: rs1,
            alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
        },
        {
             title:"Oneroof Solutions Pvt Ltd",
             link:"https://oneroofsolution.co.in/",
            logo:k2,
            image: rs2,
            alt: "E-Commerce Development illustration with various icons representing online shopping"
        },
        {
             title:"Rohit Arts",
             link:"https://rohitarts.com/",
            logo:k3,
            image: rs3,
            alt: "Web UX/UI illustration with a computer monitor displaying various web design elements"
        },
       
    ];

    return (
        <div className={classes.container}>
          
                {services.map((service, index) => (
                      <a href={service.link} className={classes.dh} key={index} target="_blank" rel="noopener noreferrer">
                 <Fade left>
                    <div key={index} className={classes.card}>
                        <div className={classes.imageContainer}>
                           <div className={classes.upper}>
                                <div className={classes.round}>

                                         <img
                                src={service.logo}
                                alt={service.alt}
                                className={classes.lgo}
                            />
                                    </div>

                                     <h2 className={classes.title}>{service.title}</h2>
                           </div>
                          
                            <img
                                src={service.image}
                                alt={service.alt}
                                className={classes.image}
                            />
                            {/* <div className={classes.buttonContainer}>
                                <button className={classes.button}>
                                    <FaArrowRight />
                                </button>
                            </div> */}
                        </div>
                    </div>
                    </Fade>
                    </a> 
                ))}
           
        </div>
    );
};

export default Service;
